<template>
  <v-card min-height="100%" class="d-flex justify-space-between flex-column">
    <v-card-title>Match Xero Contacts</v-card-title>
    <v-card-text v-if="!fuzzyMatch">
      <h4>No matching Xero contacts found</h4>
    </v-card-text>
    <v-card-text v-if="fuzzyMatch">
      <h4>The following Xero contacts may match this new registration</h4>
      <v-radio-group v-model="matchContact">
        <v-radio
          v-for="match in fuzzyMatch"
          :key="match.id"
          :label="match.name"
          :value="match"
        ></v-radio>
      </v-radio-group>
    </v-card-text>
    <v-card-actions>
      <v-col>
        <v-btn width="100%" class="mb-2" color="primary" @click="approveExisting" :disabled="!matchContact" >{{matchContact ? `Link to ${matchContact.name}` : 'Link to existing contact'}}</v-btn>
        <v-btn width="100%" class="mb-2" color="primary" @click="approve" >Create new Xero contact</v-btn>
        <v-btn width="100%" class="mb-2" color="primary" @click="reject">Reject</v-btn>
      </v-col>
    </v-card-actions>
    <confirm-dialog ref="confirmDialog" />
  </v-card>
</template>

<script>
import FuzzySet from 'fuzzyset'
import ConfirmDialog from '@/components/ConfirmDialog.vue'

export default {
  props: {
    registration: Object,
    contacts: Array,
    contactNames: Array
  },
  components: {
    ConfirmDialog
  },
  data () {
    return {
      matchContact: null,
      statusMessages: {
      }
    }
  },
  created: function () {
  },
  mounted: function () {
  },
  computed: {
    fuzzyMatch: function () {
      const set = FuzzySet(this.contactNames, false, 2, 3)
      const fuzzy = set.get(this.registration.customerName, null, 0.45)
      if (fuzzy) {
        const results = this.contacts.filter(item => {
          if (fuzzy[0][1] === item.name) {
            return true
          } else if (fuzzy[1] && fuzzy[1][1] === item.name) {
            return true
          } else return false
        })
        return results
      } else return null
    }
  },
  methods: {
    async approveExisting () {
      if (
        await this.$refs.confirmDialog.open(
          `Assign this registration to ${this.matchContact.name}?`,
          `Be careful with this, and ensure that the new registration really does relate to ${this.matchContact.name}. A confirmation email will be sent to the user`
        )
      ) {
        this.$emit('approve', { registrationId: this.registration.id, contact: this.matchContact })
      }
    },
    async approve () {
      if (
        await this.$refs.confirmDialog.open(
          `Approve this registration? (${this.registration.customerName})`,
          'This will create an app login, and will also create a new Xero contact. A confirmation email will be sent to the user'
        )
      ) {
        this.$emit('approve', { registrationId: this.registration.id, contact: null })
      }
    },
    async reject () {
      if (
        await this.$refs.confirmDialog.open(
          `Reject this registration? (${this.registration.customerName})`,
          'The request will be flagged as rejected. No other actions will be taken'
        )
      ) {
        this.$emit('reject', { id: this.registration.id })
      }
    }
  }
}
</script>

